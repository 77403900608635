<template>
    <div class="flag-body">
        <div @click="sl('en')"><img src="../assets/EN.jpg"/></div>
        <div @click="sl('de')"><img src="../assets/DE.jpg"/></div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'LanguageSelector',
  methods: {
      ...mapMutations(['setLanguage']),
      sl(l) {
          this.setLanguage(l);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .flag-body {
        width:100%;
        display: flex;
        justify-content: center;
        margin: 10px;

        div {
            padding: 10px;
        }
    }
</style>
