<template>
    <div class="f">
        <br>
        <div>{{lang.registerText}}</div>
        <br>
        <div v-if="error" class="error">{{lang.error}}</div>
        <br>
        <div>
            <div>{{lang.name}}:</div>
            <div><input v-model="form.name"/></div>
        </div>
        <div>
            <div>{{lang.email}}*:</div>
            <div>
                <input v-model="form.email"/>
                <template v-if="form.email.length >= 3 && !validateEmail">&nbsp;{{lang.invalid}}</template>
            </div>
        </div>
        <div>
            <div>{{lang.cong}}:</div>
            <div><input v-model="form.cong" /></div>
        </div>
        <div>
            <div>{{lang.rec}}</div>
            <div><input v-model="form.rec" /></div>
        </div>
        <div>
            <div>{{lang.text}}</div>
            <div>
                <textarea v-model="form.text">
                </textarea>
            </div>
        </div>
        <div>
            <div>{{lang.dsgvoHint}}</div>
            <div>
                <input type="checkbox" v-model="form.dsgvo" />
            </div>
        </div>
        <br>
        <div class="button">
            <button :disabled="form.email.length <= 3 || !validateEmail || !form.dsgvo" @click="send()">{{lang.send}}</button>
        </div>
    </div>  
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CForm',
  data(){
      return {
          form: {
            name: '',
            email: '',
            cong: '',
            rec: '',
            text: '',
            error: false,
            dsgvo: false,
          },
      }
  },
  methods: {
      ...mapActions(['sendForm']),
      async send(){
          //this.sendForm({form: this.form });
          let ret = await this.axios.post('add.php', 
          JSON.stringify({
            name: this.form.name,
            email: this.form.email,
            rec: this.form.rec,
            cong: this.form.cong,
            text: this.form.text
          })
          );
        if(ret.data.ok) {
            this.form = {
                name: '',
                email: '',
                cong: '',
                rec: '',
                text: '',
            };
        }
        else{
            this.error;
        }
    }
  },
  computed: {
    ...mapGetters(['lang']),
    validateEmail() {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;// eslint-disable-line
        if (reg.test(this.form.email)) {
            return true;
        } 
        return false;
    }
  },
}
</script>

<style scoped lang="scss">
    .f > div {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        > div {
            flex-basis: 50%;
        }
        textarea, input {
            width: 177px;
        }
        textarea {
            height: 90px;
        }
    }
    .button, .error {
        justify-content: center;
    }

    .error {
        font-weight: bolder;
        color: maroon;
    }
</style>
