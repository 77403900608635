
export default {
    state() {
        return {
            selectedLang: 'de',
            lang: {
                de: {
                    passed: `Das war der DDF 01.01.2021. Vielen Dank für die gute Laune. Wir sehen uns hoffentlich bald wieder. Der nächste DDF ist bereits in Planung.`,
                    title: '60’s till 2022',
                    init: 'Es ist wieder soweit!!! Das Datum für den nächsten DDF steht fest:',
                    date: '01.Januar 2022!',
                    text1: `Das Motto lautet: 60’s till 2022!\n
                    Unsere musikalische Zeitreise beginnend mit den 60ern bis heute.DJ TK – Thomas Kemptner wird seine Turntables heiß laufen lassen und uns einen unvergesslichen musikalischen Abend bereiten.Wer möchte, kann sich in Schale werfen und seinen Wohnraum in ein Tanzparkett umgestalten.Für die, die es etwas bequemer und relaxter angehen möchten, haben wir unsere heißbegehrten Breakoutrooms den gesamten Abend für euch geöffnet.
                    
                    Wir freuen uns schon sehr auf einen tollen Abend mit euch.Gerne dürft ihr die Einladung an Brüder und Schwestern weltweit verteilen.Es sind seither schon tolle Freundschaften entstanden, die wir nicht mehr missen möchten. 
                    
                    Und da wir die letzten Male gefragt wurden, ob man für die Organisation und vor allem den DJs nicht etwas Dankbarkeit erweisen kann, haben wir für euch einen „Donate“- Button kreiert, den ihr gerne nutzen könnt.`,
                    text2: `Ablauf:
Einlass: 19:45 Uhr (GMT+1)
Eröffnung: 20:00 Uhr (auf DE und EN)
Musik-Start: 20:10 Uhr
Breakout-Rooms-Start: 20:10 Uhr
Voraussichtliches musikalisches Ende: ca. 01:00 Uhr
Gespräche können weiter geführt werden, bis der Host ins Bett möchte. Also einfach bei Laune halten. 😉

Wir freuen uns schon sehr auf euch. Habt ihr Anregungen, lasst sie uns wissen!
Eure Organisatoren
Gabriele Gimigliano, Sabrina & Eric Hawle
`,
                    registerText: `Du kannst dich nun gerne hier eintragen, um sowohl für dieses als auch für künftige Events Benachrichtigungen zu erhalten.`,
                    zoomID: 'ZOOM-ID: 989 0086 8822',
                    zoomPW: 'KEIN PASSWORT ERFORDERLICH!!',
                    name: 'Name',
                    email: 'E-Mail',
                    invalid: 'Ungültige Adresse',
                    cong: 'Versammlung',
                    rec: `Wer hat dir vom DDF erzählt?`,
                    text: 'Willst du uns was sagen?',
                    send: 'Absenden',
                    error: '!!! Irgendwas ist schief gelaufen. Bitte prüfe deine Eingabe !!!',
                    dsgvoHint: 'Ich akzeptiere die DSGVO',
                    dsgvo: `Datenschutzerklärung

                        Grundlegendes

                        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber [siehe Impressum]informieren.

                        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften.Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.

                        Definitionen der verwendeten Begriffe(z.B.“personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.

                                                Zugriffsdaten

                        Wir, der Websitebetreiber bzw.Seitenprovider, erheben aufgrund unseres berechtigten Interesses(s.Art. 6 Abs. 1 lit.f.DSGVO) Daten über Zugriffe auf die Website und speichern diese als „Server- Logfiles“ auf dem Server der Website ab.Folgende Daten werden so protokolliert:

                                •	Besuchte Website
                                •	Uhrzeit zum Zeitpunkt des Zugriffes
                                •	Menge der gesendeten Daten in Byte
                                •	Quelle/ Verweis, von welchem Sie auf die Seite gelangten
                            •	Verwendeter Browser
                            •	Verwendetes Betriebssystem
                            •	Verwendete IP- Adresse

                        Die Server - Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht.Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z.B.Missbrauchsfälle aufklären zu können.Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.

                            Cookies

                        Es werden aktuell keine Cookies gespeichert

                        Umgang mit Kontaktdaten

                        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen werden kann.Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.

                        Rechte des Nutzers

                        Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden.Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten.Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen.Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.

                        Löschung von Daten

                        Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten(z.B.Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten.Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht.Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung.In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.

                            Widerspruchsrecht

                        Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.

                        Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl.der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E - Mail - Adresse: dumpcatch@gmail.com
                        `,
                    impressum: `Informationen und Offenlegung gemäß §5 (1) ECG, § 25 MedienG, § 63 GewO und § 14 UGB

Webseitenbetreiber: Eric Hawle

Anschrift: Rossmarkt, 4, 4902 Wolfsegg am Hausruck

Kontaktdaten:
Telefon: 069981374997
Email: meet@jw-apps.com

Anwendbare Rechtsvorschrift: www.ris.bka.gv.at
Berufsbezeichnung:

Online Streitbeilegung: Verbraucher, welche in Österreich oder in einem sonstigen Vertragsstaat der ODR-VO niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder Dienstleistungen im Rahmen einer Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine Plattform hierfür bereit: https://ec.europa.eu/consumers/odr

Urheberrecht: Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich ist, diversen Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem Material, welche urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des Webseitenbetreibers.

Haftungsausschluss: Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Sollten Sie dennoch auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach § 17 Abs. 2 ECG umgehend zu entfernen.
Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den betroffenen Inhalt umgehend entfernen.

Quelle: fairesRecht in Kooperation mit Geld zurück bei illegalen Online-Casinos`
                },
                en: {
                    passed: `Its over. DDF 01.01.2021 is over and it was awesome. Thanks for being with us. Next DDF is coming up soon.`,
                    title: '60’s till 2022',
                    init: `It's that time again!!! The date for the next DDF is set:`,
                    date: '01 January 2022!',
                    text1: `The motto is: 60's till 2022!\n
                    Our musical time travel starting with the 60's until today: DJ TK - Thomas Kemptner will let his turntables run hot and prepare us an unforgettable musical evening.
If you want, you can dress up and transform your living room into a dance floor. For those who want to take it a bit more comfortable and relaxed, we will have our much sought-after breakout rooms open for you the whole evening.

We are looking forward to a great evening with you. Feel free to distribute the invitation to brothers and sisters worldwide. Since then, great friendships have been formed that we would not want to miss.

And since we have been asked the last few times if you can't show some gratitude for the organization and especially for the DJs, we have created a "Donate" button for you, which you are welcome to use.
`,
                    text2: `Procedure:
Admission: 19:45 (GMT+1)
Opening: 20:00 (in DE and EN)
Music-Start: 20:10
Breakout rooms start: 20:10 hrs
Expected musical end: approx. 01:00 hrs
Conversations can continue until the host wants to go to bed. So just keep your spirits up 😉.

We are really looking forward to seeing you. If you have any suggestions, let us know!
Your organizers
Gabriele Gimigliano, Sabrina & Eric Hawle
`,
                    registerText: `You are now welcome to sign up here to receive notifications for both this and future events. `,
                    zoomID: 'ZOOM-ID: 989 0086 8822',
                    zoomPW: 'NO PASSWORD REQUIRED!!!',
                    name: 'Name',
                    email: 'E-Mail',
                    invalid: 'Ungültige Adresse',
                    cong: 'Congregation',
                    rec: 'Who told you about DDF?',
                    text: 'Want to tell us something?',
                    send: 'Send',
                    error: '!!! Oh shoot. Something went wrong. Plz check your input !!!',
                    dsgvoHint: 'Yes, i accept the DSGVO',
                    dsgvo: `Datenschutzerklärung

                        Grundlegendes

                        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber [siehe Impressum]informieren.

                        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften.Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.

                        Definitionen der verwendeten Begriffe(z.B.“personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.

                                                Zugriffsdaten

                        Wir, der Websitebetreiber bzw.Seitenprovider, erheben aufgrund unseres berechtigten Interesses(s.Art. 6 Abs. 1 lit.f.DSGVO) Daten über Zugriffe auf die Website und speichern diese als „Server- Logfiles“ auf dem Server der Website ab.Folgende Daten werden so protokolliert:

                                •	Besuchte Website
                                •	Uhrzeit zum Zeitpunkt des Zugriffes
                                •	Menge der gesendeten Daten in Byte
                                •	Quelle/ Verweis, von welchem Sie auf die Seite gelangten
                            •	Verwendeter Browser
                            •	Verwendetes Betriebssystem
                            •	Verwendete IP- Adresse

                        Die Server - Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht.Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z.B.Missbrauchsfälle aufklären zu können.Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.

                            Cookies

                        Es werden aktuell keine Cookies gespeichert

                        Umgang mit Kontaktdaten

                        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen werden kann.Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.

                        Rechte des Nutzers

                        Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden.Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten.Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen.Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.

                        Löschung von Daten

                        Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten(z.B.Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten.Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht.Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung.In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.

                            Widerspruchsrecht

                        Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.

                        Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl.der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E - Mail - Adresse: dumpcatch@gmail.com
                        `,
                    impressum: `Informationen und Offenlegung gemäß §5 (1) ECG, § 25 MedienG, § 63 GewO und § 14 UGB

Webseitenbetreiber: Eric Hawle

Anschrift: Rossmarkt, 4, 4902 Wolfsegg am Hausruck

Kontaktdaten:
Telefon: 069981374997
Email: meet@jw-apps.com

Anwendbare Rechtsvorschrift: www.ris.bka.gv.at
Berufsbezeichnung:

Online Streitbeilegung: Verbraucher, welche in Österreich oder in einem sonstigen Vertragsstaat der ODR-VO niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder Dienstleistungen im Rahmen einer Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine Plattform hierfür bereit: https://ec.europa.eu/consumers/odr

Urheberrecht: Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich ist, diversen Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem Material, welche urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des Webseitenbetreibers.

Haftungsausschluss: Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Sollten Sie dennoch auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach § 17 Abs. 2 ECG umgehend zu entfernen.
Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den betroffenen Inhalt umgehend entfernen.

Quelle: fairesRecht in Kooperation mit Geld zurück bei illegalen Online-Casinos`

                },
            }
        }
    },
    mutations: {
        setLanguage(state, payload) {
            state.selectedLang = payload;
        },
    },
    actions: {
        async sendForm(context, form) {
            console.log(form);
            let ret = await this.axios.get('https://meet.jw-apps.com/add.php?form=' + encodeURIComponent(form));
            console.log(ret);
        }
    },
    getters: {
        selectedLang: state => state.selectedLang,
        lang: state => state.lang[state.selectedLang]
    }

}