import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import axios from "axios";
import VueAxios from 'vue-axios'
import base from './store/store.js'

const store = createStore(base);

let app = createApp(App);
app.use(VueAxios, axios);
app.use(store);

app.mount('#app')
