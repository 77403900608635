<template>
  <language-selector/>
  <div class="parent">
    <div class="scroll" ref="scroll">
      <!-- <h1>{{lang.title}}</h1>
      <div>{{lang.init}}</div><br>
      <div class="date">{{lang.date}}</div><br>
      <div>{{lang.text1}}</div> -->
      <div>{{lang.passed}}</div>
      <div>{{lang.donateText}}</div>
      <div class="donate">
        <img src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" id="donate-button" style="cursor: pointer;" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" @click="openPP()">
      </div>
      <!-- <div class="ID">{{lang.zoomID}}</div>
      <div class="ID">{{lang.zoomPW}}</div><br>
      <div class="ID"><a style="color:#fff" href="https://zoom.us/j/98900868822" target="_blank">LINK</a></div> -->
      <!-- <div>{{lang.text2}}</div> -->
      <c-form />
      <br><br>
      <div ref="imp" />
      <div v-show="imp">{{lang.impressum}}{{lang.dsgvo}}</div>
    </div>
    <div class="imp" @click="showImp()">impressum | dsgvo</div>
  </div>
</template>

<script>
import LanguageSelector from './LanguageSelector.vue';
import CForm from './CForm.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'MainText',
  data() {
    return {
      imp: false,
    }
  },
  components: {
    LanguageSelector,
    CForm
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    openPP() {
      window.open('https://www.paypal.com/donate/?hosted_button_id=H7TP2XX4NUYSY', '_blank')
    },
    showImp(){
      this.imp = !this.imp;
    }
  },
  updated: function(){
      if(this.imp) {
        this.$refs['scroll'].scrollTo(0, this.$refs['imp'].offsetTop -120);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h1 {
    margin: 0px;
    padding: 15px;
    margin: 0 auto;
    min-width: 300px;
  }
  .scroll > div {
    margin: 0 auto;
    white-space: pre-line;
    width:80%;
  }
  .date {
    text-align: center;
    font-weight: bolder;
  }
  .donate {
    padding:20px;
    display: flex;
    justify-content: center;
  }
  .ID {
    display:flex;
    justify-content: center;
    font-weight: bolder;
  }
  .scroll {
    display: flex;
    flex-direction: column;    
    height: 100%;
    overflow-x: auto;
    margin-bottom:30px;
  }
  .parent {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 30px;
    padding-bottom: 60px;
    box-sizing: border-box;
    height: 80%;
  }
  .imp {
    cursor:pointer;
    text-align: center;
  }
</style>
