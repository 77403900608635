<template>
  <MainText />
  <div class="bg" />
</template>

<script>
import MainText from './components/MainText.vue';


export default {
  name: 'App',
  components: {
    MainText,
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
  body {
    background-color:#000;
    margin:0px;
    color: #fff;
    font-size: larger;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    margin: 0 auto;
    min-width: 1100px;
    width: 60%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.5);
    position: relative;
    box-shadow: inset 0px 0px 25px 15px #000;
    border: 2px solid #000;
    box-sizing: border-box;
  }

  .bg {
    position: absolute;
    background-image: url(./assets/Turntable_ganzes_Bild.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    width:100%;
    height: 100%;
    z-index: -5;
  }

  @media (max-width: 990px) {
    .bg {
      background-position: center 0;
    }
  }

  @media (max-width: 1100px) {
    #app {
      min-width: initial;
      width:100%;
    }
  }
</style>
